var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalCrearProveedor",attrs:{"titulo":"Editar proveedor","icon":"proveedor","cargado-modal":_vm.cargando,"no-aceptar":"","adicional":"Editar","no-cancelar":"","cerrar":"","tamano":"modal-lg"},on:{"adicional":_vm.editarProveedor}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row mx-0 pb-3"},[_c('div',{staticClass:"col-12 d-middle-center py-3"},[_c('slim-cropper',{ref:"fotoProveedor",staticClass:"border cr-pointer cropper-proveedor",attrs:{"options":_vm.slimOptions}})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"pl-3 text-general  f-14"},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"rules":"required|max:25","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre del proveedor"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"pl-3 text-general  f-14"},[_vm._v("NIT")]),_c('ValidationProvider',{attrs:{"rules":"max:20","name":"NIT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Ingresa el NIT"},model:{value:(_vm.model.nit),callback:function ($$v) {_vm.$set(_vm.model, "nit", $$v)},expression:"model.nit"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 my-3"},[_c('p',{staticClass:"pl-3 text-general  f-14"},[_vm._v("Dirección")]),_c('ValidationProvider',{attrs:{"rules":"required|max:100","name":"dirección"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"dirección"},model:{value:(_vm.model.direccion),callback:function ($$v) {_vm.$set(_vm.model, "direccion", $$v)},expression:"model.direccion"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('p',{staticClass:"pl-3 text-general  f-14"},[_vm._v("Observaciones")]),_c('ValidationProvider',{attrs:{"rules":"max:750","name":"observaciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Comentario del proveedor","type":"textarea","rows":3},model:{value:(_vm.model.observaciones),callback:function ($$v) {_vm.$set(_vm.model, "observaciones", $$v)},expression:"model.observaciones"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"país"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2 text-general"},[_vm._v("País")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":""},on:{"change":_vm.consultarCiudadesEstados},model:{value:(_vm.id_pais),callback:function ($$v) {_vm.id_pais=$$v},expression:"id_pais"}},_vm._l((_vm.paises),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"ciudad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2 text-general"},[_vm._v("Ciudad")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","remote":"","clearable":"","reserve-keyword":"","remote-method":_vm.consultarCiudadesEstados},model:{value:(_vm.model.idm_ciudad),callback:function ($$v) {_vm.$set(_vm.model, "idm_ciudad", $$v)},expression:"model.idm_ciudad"}},_vm._l((_vm.ciudades),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])]),_c('div',{staticClass:"col-auto"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }